import {
  createSettlement,
  getSellInfo,
  getSettlementInfo,
  getUnsettlementInfo,
} from "@/api/resport";
import dayjs from "dayjs";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useSellInfo = ({
  startDate,
  endDate,
  shoppingMall = "ko",
  page = "0",
  limit = "10",
  sellReportType = "item",
}) => {
  const shouldFetch =
    startDate && endDate && shoppingMall && page && limit ? true : false;

  const startDayjs = dayjs(startDate).startOf("month").format("YYYY-MM-DD");
  const endDayjs = dayjs(endDate).endOf("month").format("YYYY-MM-DD");

  const {
    data: sellInfo,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    [
      "sellInfo",
      { startDayjs, endDayjs, shoppingMall, page, limit, sellReportType },
    ],
    () =>
      getSellInfo({
        startDate: startDayjs,
        endDate: endDayjs,
        shoppingMall,
        page,
        limit,
        sellReportType,
      }),
    {
      enabled: shouldFetch,
    }
  );

  return {
    sellInfo,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useUnsettlementInfo = () => {
  const {
    data: unsettlementInfo,
    isLoading,
    isSuccess,
    isError,
  } = useQuery("unsettlementInfo", () => getUnsettlementInfo());

  return {
    unsettlementInfo,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useSettlementInfo = ({
  startDate,
  endDate,
  page = "0",
  limit = "10",
}) => {
  const shouldFetch = startDate && endDate && page && limit ? true : false;

  const startDayjs = dayjs(startDate).startOf("month").format("YYYY-MM-DD");
  const endDayjs = dayjs(endDate).endOf("month").format("YYYY-MM-DD");

  const {
    data: settlementInfo,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    ["settlementInfo", { startDayjs, endDayjs, page, limit }],
    () =>
      getSettlementInfo({
        startDate: startDayjs,
        endDate: endDayjs,
        page,
        limit,
      }),
    {
      enabled: shouldFetch,
    }
  );

  return {
    settlementInfo,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useCreateSettlementMutation = (successCallback) => {
  const queryClient = useQueryClient();

  const createSettlementMutation = useMutation(createSettlement, {
    onSuccess: () => {
      queryClient.invalidateQueries("unsettlementInfo");
      successCallback();
    },
  });

  const submit = () => {
    createSettlementMutation.mutate();
  };

  return submit;
};
