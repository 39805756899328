import { SERVER_URL } from "@/api/info";
import { rest } from "msw";

const setting = [
  /*
   * Admin List GET API Mock
   */
  rest.get(`${SERVER_URL}/admin`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: {
          type: "ADMIN",
          message: "SUCCESS",
          data: {
            totalPage: 2,
            totalCount: 1, // 검색결과 전체 수
            adminList: [
              {
                id: "관리자 아이디1",
                name: "관리자 이름",
                lastUpdateDate: "2021-08-01 00:00:00", // {type: Date},
                level: "대표운영자", // or "부운영자"
              },
              {
                id: "관리자 아이디2",
                name: "관리자 이름",
                lastUpdateDate: "2021-08-01 00:00:00", // {type: Date},
                level: "대표운영자", // or "부운영자"
              },
              {
                id: "관리자 아이디3",
                name: "관리자 이름",
                lastUpdateDate: "2021-08-01 00:00:00", // {type: Date},
                level: "대표운영자", // or "부운영자"
              },
              {
                id: "관리자 아이디4",
                name: "관리자 이름",
                lastUpdateDate: "2021-08-01 00:00:00", // {type: Date},
                level: "대표운영자", // or "부운영자"
              },
            ],
          },
        },
      })
    );
  }),

  /*
   * Admin Check ID GET API Mock
   */
  rest.get(`${SERVER_URL}/admin/duplication`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json());
  }),
  /*
   * Admin Create POST API Mock
   */
  rest.post(`${SERVER_URL}/admin`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json());
  }),
  /*
   * Admin Update PUT API Mock
   */
  rest.put(`${SERVER_URL}/admin/user/:id`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json());
  }),
  /*
   * Admin Delete PUT API Mock
   */
  rest.delete(`${SERVER_URL}/admin`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json());
  }),
];

export default setting;
