import { SERVER_URL } from "@/api/info";
import userList from "@/mocks/data/common/user";
import { rest } from "msw";

const profile = [
  rest.get(`${SERVER_URL}/profile/artist`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: {
          message: userList[0].message,
          data: userList[0],
        },
      })
    );
  }),
  rest.get(`${SERVER_URL}/profile/admin`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: {
          message: userList[3].message,
          data: userList[3],
        },
      })
    );
  }),
  rest.put(`${SERVER_URL}/profile/artist`, (req, res, ctx) => {
    const token = req.cookies.token;
    const user = userList.find((user) => user.token === token);

    return res(ctx.status(200), ctx.json(user));
  }),
  rest.put(`${SERVER_URL}/profile/admin`, (req, res, ctx) => {
    const token = req.cookies.token;
    const user = userList.find((user) => user.token === token);

    return res(ctx.status(200), ctx.json(user));
  }),
  rest.get(`${SERVER_URL}/settlement/info`, (req, res, ctx) => {
    const token = req.cookies.token;
    const user = userList.find((user) => user.token === token);

    return res(
      ctx.status(200),
      ctx.json({
        success: {
          message: user.settlementMessage,
          data: user,
        },
      })
    );
  }),
  rest.put(`${SERVER_URL}/settlement/info`, (req, res, ctx) => {
    const token = req.cookies.token;
    const user = userList.find((user) => user.token === token);

    return res(ctx.status(200), ctx.json(user));
  }),
];

export default profile;
