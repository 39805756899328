const userList = [
  {
    type: "artist",
    _id: 1,
    token: "QWEQWEeeee",
    id: "qwe",
    pw: "qwe",
    nameKor: "김민수",
    nameEng: "Minsoo Kim",
    profileImg: "http://localhost:3000/assets/profile/profile.png",
    storeBannerImg: "http://localhost:3000/assets/profile/profile.png",
    introKor: "김민수",
    introEng: "Minsoo Kim",
    abroadSaleOption: "notSell",
    phoneNum: "010-1234-5678",
    email: "qwe@qwe.kr",
    snsInstagram: "https://www.instagram.com/",
    snsTwitter: "https://www.facebook.com/",
    snsBlog: "https://www.naver.com/",
    snsYoutube: "https://www.youtube.com/",
    snsGrafolio: "https://www.grafolio.com/",
    snsEtc1: "",
    snsEtc2: "",

    bankName: "서재완",
    bankAccount: "??",
    bankAccountHolder: "무요!",
    businessRegistration: "http://localhost:3000/assets/profile/profile.png",
    businessRegistrationFileSize: "12000000",

    isTemp: false,
    isNew: false,
    message: "EMPTY PROFILE",
    settlementMessage: "CREATE NEW SETTLEMENT INFO",
    lastLoginDate: "2021-08-01 00:00:00",
    lastUpdateDate: "2021-08-01 01:00:00",
    lastUpdateSettlementDate: "2021-08-01 01:00:00",
  },
  {
    type: "artist",
    _id: 2,
    token: "QWEQWEeeee",
    id: "asd",
    pw: "asd",
    nameKor: "김민수",
    nameEng: "Minsoo Kim",
    profileImg: "http://localhost:3000/assets/profile/profile.png",
    storeBannerImg: "http://localhost:3000/assets/profile/profile.png",
    introKor: "김민수",
    introEng: "Minsoo Kim",
    abroadSaleOption: "notSell",
    phoneNum: "010-1234-5678",
    email: "qwe@qwe.kr",
    snsInstagram: "https://www.instagram.com/",
    snsTwitter: "https://www.facebook.com/",
    snsBlog: "https://www.naver.com/",
    snsYoutube: "https://www.youtube.com/",
    snsGrafolio: "https://www.grafolio.com/",
    snsEtc1: "",
    snsEtc2: "",

    bankName: "",
    bankAccount: "",
    bankAccountHolder: "",
    BusinessRegistration: "",

    isTemp: true,
    isNew: false,
  },
  {
    type: "artist",
    _id: 3,
    token: "QWEQWEeeee",
    id: "zxc",
    pw: "zxc",
    nameKor: "김민수",
    nameEng: "Minsoo Kim",
    profileImg: "http://localhost:3000/assets/profile/profile.png",
    storeBannerImg: "http://localhost:3000/assets/profile/profile.png",
    introKor: "김민수",
    introEng: "Minsoo Kim",
    abroadSaleOption: "notSell",
    phoneNum: "010-1234-5678",
    email: "qwe@qwe.kr",
    snsInstagram: "https://www.instagram.com/",
    snsTwitter: "https://www.facebook.com/",
    snsBlog: "https://www.naver.com/",
    snsYoutube: "https://www.youtube.com/",
    snsGrafolio: "https://www.grafolio.com/",
    snsEtc1: "",
    snsEtc2: "",

    bankName: "",
    bankAccount: "",
    bankAccountHolder: "",
    BusinessRegistration: "",

    isTemp: false,
    isNew: true,
  },
  {
    type: "admin",
    _id: 4,
    token: "QWEQWEeeee",
    id: "qaz",
    pw: "qaz",
    name: "서재완",
    nameKor: "김민수",
    nameEng: "Minsoo Kim",
    profileImg: "http://localhost:3000/assets/profile/profile.png",
    storeBannerImg: "http://localhost:3000/assets/profile/profile.png",
    introKor: "김민수",
    introEng: "Minsoo Kim",
    abroadSaleOption: "notSell",
    phoneNum: "010-1234-5678",
    email: "qwe@qwe.kr",
    snsInstagram: "https://www.instagram.com/",
    snsTwitter: "https://www.facebook.com/",
    snsBlog: "https://www.naver.com/",
    snsYoutube: "https://www.youtube.com/",
    snsGrafolio: "https://www.grafolio.com/",
    snsEtc1: "",
    snsEtc2: "",

    bankName: "",
    bankAccount: "",
    bankAccountHolder: "",
    BusinessRegistration: "",

    accessCategory: [
      "DASHBOARD",
      "ARTIST",
      "PRODUCT",
      "SETTLEMENT_REQUEST",
      "SETTLEMENT_REPORT",
      "SETTLEMENT_CREATE",
      "OPERATION",
    ],
    level: "대표운영자",
  },
];

export default userList;
