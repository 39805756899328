import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import worker from "./mocks/worker";

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_DEV === "LOCAL"
) {
  worker.start();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
