import { rest } from "msw";
import { SERVER_URL } from "@/api/info";
import settlementData, {
  unSettlementData,
} from "@/mocks/data/artist/settlement";

const settlement = [
  rest.get(`${SERVER_URL}/report/settlement`, (req, res, ctx) => {
    const result = settlementData[""] ?? [];

    if (!result) {
      res(ctx.status(400));
    }

    return res(
      ctx.status(200),
      ctx.json({
        success: {
          data: {
            pageCnt: 3,
            totalItem: 120,
            settlementReport: [
              {
                date: "2021-08-01 08:00:00",
                period: {
                  start: "2021-08-01 08:00:00",
                  end: "2021-08-01 08:00:00",
                },
                amount: 456000,
                reportFile: "http://localhost:3000/assets/profile/profile.png",
              },
              {
                date: "2021-08-01 08:00:00",
                period: {
                  start: "2021-08-01 08:00:00",
                  end: "2021-08-01 08:00:00",
                },
                amount: 456000,
                reportFile: "http://localhost:3000/assets/profile/profile.png",
              },
              {
                date: "2021-08-01 08:00:00",
                period: {
                  start: "2021-08-01 08:00:00",
                  end: "2021-08-01 08:00:00",
                },
                amount: 456000,
                reportFile: "http://localhost:3000/assets/profile/profile.png",
              },
            ],
          },
        },
      })
    );
  }),

  rest.get(`${SERVER_URL}/report/unsettlement`, (req, res, ctx) => {
    const result = unSettlementData;

    if (!result) {
      res(ctx.status(400));
    }

    return res(
      ctx.status(200),
      ctx.json({
        success: {
          data: {
            unsettlementDate: "2021-08-01 08:00:00",
            updateTime: "2021-07-30 08:00:00",
            amount: {
              ko: 340000,
              en: 130000,
              jp: 230000,
              total: 700000,
            },
          },
        },
      })
    );
  }),

  rest.post(`${SERVER_URL}/report/settlement`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
];

export default settlement;
