import { SERVER_URL } from "@/api/info";
import { settlementList } from "@/mocks/data/admin/settlement";
import { rest } from "msw";

export default [
  /*
   * Settlement List GET API Mock
   */
  rest.get(`${SERVER_URL}/admin/settlement`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: settlementList,
        maxPage: 11,
        totalLength: 100,
      })
    );
  }),

  /*
   * Settlement Request List GET API Mock
   */
  rest.get(`${SERVER_URL}/settlement/request`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: {
          data: {
            total: 21,
            pageCnt: 2,
            settlementRequestList: [
              {
                requestCode: "P1123123",
                artistNameEng: "name",
                requestDate: "2021-08-01 00:00:00",
                settlementPeriod: {
                  start: "2021-08-01 00:00:00",
                  end: "2021-08-01 00:00:00",
                },
                settlementAmount: 123333,
                settlementStatus: "미정산", // "정산 완료"
              },
              {
                requestCode: "P1123124",
                artistNameEng: "name",
                requestDate: "2021-08-01 00:00:00",
                settlementPeriod: {
                  start: "2021-08-01 00:00:00",
                  end: "2021-08-01 00:00:00",
                },
                settlementAmount: 123333,
                settlementStatus: "정산 완료", // "정산 완료"
              },
              {
                requestCode: "P1123152",
                artistNameEng: "name",
                requestDate: "2021-08-01 00:00:00",
                settlementPeriod: {
                  start: "2021-08-01 00:00:00",
                  end: "2021-08-01 00:00:00",
                },
                settlementAmount: 123333,
                settlementStatus: "미정산", // "정산 완료"
              },
            ],
          },
        },
      })
    );
  }),

  /*
   * Settlement Request Ready List GET API Mock
   */
  rest.get(`${SERVER_URL}/settlement/report`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: {
          data: {
            pageCnt: 3, // 페이지네이션 총 페이지 수
            settlementList: [
              {
                artistNameEng: "아티스트 영문명",
                period: {
                  start: "2021-08-11 00:00:00",
                  end: "2021-08-11 00:00:00",
                },
                amount: 123123,
                admin: {
                  name: "관리자 이름",
                  id: "관리자 ID",
                },
                reportFile: "", // 생성완료: file path string
              },
            ],
          },
        },
      })
    );
  }),

  /*
   * Settlement Statement List GET API Mock
   */
  rest.get(`${SERVER_URL}/settlement`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: {
          type: "SETTLEMENT_REPORT",
          message: "SUCCESS",
          data: {
            total: 123,
            pageCnt: 10, // 페이지네이션 총 수
            // no settlement -> return empty list
            settlementReport: [
              {
                date: "2021-08-01 00:00:00",
                reportCode: "P1233",
                period: {
                  start: "2021-08-01 00:00:00",
                  end: "2021-08-01 00:00:00",
                },
                artistNameEng: "아티스트 영문명",
                amount: 456000,
                type: "요청 정산", // "정기 정산"
                reportFile: "{file uri string}",
              },
              {
                date: "2021-08-01 00:00:00",
                reportCode: "P1234",
                period: {
                  start: "2021-08-01 00:00:00",
                  end: "2021-08-01 00:00:00",
                },
                artistNameEng: "아티스트 영문명",
                amount: 456000,
                type: "정기 정산", // "정기 정산"
                reportFile: "{file uri string}",
              },
              {
                date: "2021-08-01 00:00:00",
                reportCode: "P1235",
                period: {
                  start: "2021-08-01 00:00:00",
                  end: "2021-08-01 00:00:00",
                },
                artistNameEng: "아티스트 영문명",
                amount: 456000,
                type: "요청 정산", // "정기 정산"
                reportFile: "{file uri string}",
              },
              {
                date: "2021-08-01 00:00:00",
                reportCode: "P1236",
                period: {
                  start: "2021-08-01 00:00:00",
                  end: "2021-08-01 00:00:00",
                },
                artistNameEng: "아티스트 영문명",
                amount: 456000,
                type: "요청 정산", // "정기 정산"
                reportFile: "{file uri string}",
              },
            ],
          },
        },
      })
    );
  }),

  /*
   * Settlement Detail GET API Mock
   */
  rest.get(`${SERVER_URL}/settlement/detail`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: {
          type: "SETTLEMENT_REPORT",
          message: "SUCCESS",
          data: {
            date: "2021-08-11 00:00:00", // 정산일
            artist: "chanran", //아티스트 영문명
            requestCode: "P45454", // 정기 정산 일 경우, 해당 속성은 nul 값을 가짐
            totalCost: 123123, // 정산금액
            period: {
              start: "2021-08-11 00:00:00",
              end: "2021-08-11 00:00:00",
            },
            settlementFile: "http://localhost:3000/assets/profile/profile.png", // 정산내역서 파일 경로
            settlementFileSize: 1000000, // 정산내역서 파일 사이즈, 단위: byte
            // 정산 내역 등록 정보
            register: {
              // 등록 관리자
              id: "admin",
              name: "관리자",
            },
            registDate: "2021-08-11 00:00:00", // 등록일
          },
        },
      })
    );
  }),

  /*
   * Settlement Request CREATE API Mock
   */
  rest.post(`${SERVER_URL}/settlement/report`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
];
