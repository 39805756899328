import { rest } from "msw";
import { SERVER_URL } from "@/api/info";
import salesData from "@/mocks/data/artist/sales";

const sales = [
  rest.get(`${SERVER_URL}/report/sell`, (req, res, ctx) => {
    const result = salesData["2023-03-30"];

    if (!result) {
      return res(ctx.status(400));
    }

    return res(
      ctx.status(200),
      ctx.json({
        success: {
          data: result,
        },
      })
    );
  }),
];

export default sales;
