import Radio from "@/components/common/form/radio";
import Info from "@/components/common/info";
import { useFormContext } from "react-hook-form";
import {
  ContentArea,
  ContentContainer,
  ContentInfo,
  ContentMobileTitle,
  ContentTitle,
} from "../Style";
import { createGrave } from "@/utils/function";

const RadioList = ({
  config: { title, name, info, list, required, errorMessage },
}) => {
  const {
    register,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { isEdit } = watch();
  const select = list.find(({ value }) => value === getValues(name));

  const error = errors[name] ? errorMessage[errors[name].type] : "";

  return (
    <ContentContainer>
      <ContentTitle isRequired={isEdit && required}>{title}</ContentTitle>
      <ContentArea>
        <Radio>
          <ContentMobileTitle isRequired>{title}</ContentMobileTitle>
          {isEdit
            ? list.map(({ title, value }) => (
                <Radio.Item
                  key={title}
                  value={value.toString()}
                  option={register(name, {
                    required,
                  })}
                >
                  {title}
                </Radio.Item>
              ))
            : select && select.title}

          {error && <Radio.Error>{error}</Radio.Error>}
        </Radio>
        {isEdit && info && (
          <ContentInfo>
            <Info>{createGrave(info)}</Info>
          </ContentInfo>
        )}
      </ContentArea>
    </ContentContainer>
  );
};

export default RadioList;
