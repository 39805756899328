import { SERVER_URL } from "@/api/info";
import artistData from "@/mocks/data/admin/artist";
import { rest } from "msw";

const artist = [
  /*
   * Artist List GET API Mock
   */
  rest.get(`${SERVER_URL}/artist`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: {
          data: {
            totalItem: 22,
            totalPage: 11,
            artistList: [
              {
                id: "id",
                nameEng: "eng",
                nameKor: "한구겅",
                lastUpdateProfile: "2021-08-01 00:00:00",
                lastUpdateSettlement: "2021-08-01 00:00:00",
              },
              {
                id: "id1",
                nameEng: "eng",
                nameKor: "한구겅",
                lastUpdateProfile: "2021-08-01 00:00:00",
                lastUpdateSettlement: "2021-08-01 00:00:00",
              },
              {
                id: "id2",
                nameEng: "eng",
                nameKor: "한구겅",
                lastUpdateProfile: "2021-08-01 00:00:00",
                lastUpdateSettlement: "2021-08-01 00:00:00",
              },
              {
                id: "id3",
                nameEng: "eng",
                nameKor: "한구겅",
                lastUpdateProfile: "2021-08-01 00:00:00",
                lastUpdateSettlement: "2021-08-01 00:00:00",
              },
              {
                id: "id4",
                nameEng: "eng",
                nameKor: "한구겅",
                lastUpdateProfile: "2021-08-01 00:00:00",
                lastUpdateSettlement: "2021-08-01 00:00:00",
              },
              {
                id: "id5",
                nameEng: "eng",
                nameKor: "한구겅",
                lastUpdateProfile: "2021-08-01 00:00:00",
                lastUpdateSettlement: "2021-08-01 00:00:00",
              },
              {
                id: "id6",
                nameEng: "eng",
                nameKor: "한구겅",
                lastUpdateProfile: "2021-08-01 00:00:00",
                lastUpdateSettlement: "2021-08-01 00:00:00",
              },
              {
                id: "id7",
                nameEng: "eng",
                nameKor: "한구겅",
                lastUpdateProfile: "2021-08-01 00:00:00",
                lastUpdateSettlement: "2021-08-01 00:00:00",
              },
              {
                id: "id8",
                nameEng: "eng",
                nameKor: "한구겅",
                lastUpdateProfile: "2021-08-01 00:00:00",
                lastUpdateSettlement: "2021-08-01 00:00:00",
              },
            ],
          },
        },
      })
    );
  }),

  /*
   * Artist Delete DELETE API Mock
   */
  rest.delete(`${SERVER_URL}/artist`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),

  /*
   * Artist Create POST API Mock
   */
  rest.post(`${SERVER_URL}/artist`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),

  /*
   * Artist Check ID GET API Mock
   */
  rest.get(`${SERVER_URL}/artist/duplication`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),

  /*
   * Artist All GET API Mock
   */
  rest.get(`${SERVER_URL}/artist/id`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: {
          data: ["id", "id1", "id2", "id3"],
        },
      })
    );
  }),

  /*
   * Artist Detail GET API Mock
   */
  rest.get(`${SERVER_URL}/admin/artist/:artistId`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(artistData[0]));
  }),

  /*
   * Artist Update PUT API Mock
   */
  rest.put(`${SERVER_URL}/admin/artist`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
];

export default artist;
