import Search from "@/components/common/search";
import { RequestContainer } from "./Style";
import Result from "@/components/admin/settlement/request";
import useUrl from "@/hooks/utils/useUrl";
import { admin } from "@text";
import { useArtistIdList } from "@/hooks/api/artist";

const {
  settlement: {
    request: {
      search: {
        select: { title: selectTitle },
        radio: { title: radioTitle, radioList },
        calendar: { title: calendarTitle },
      },
    },
  },
} = admin;

const Request = () => {
  const { changeQuery } = useUrl();
  const { artistIdList, isSuccess: isArtistSuccess } = useArtistIdList();

  return (
    <RequestContainer>
      <Search submit={changeQuery}>
        {isArtistSuccess && (
          <Search.Select
            title={selectTitle}
            artistList={artistIdList.success.data}
            name={"artistId"}
          />
        )}
        <Search.Radio
          title={radioTitle}
          radioList={radioList}
          name={"settlementStatus"}
        />
        <Search.Calendar title={calendarTitle} />
      </Search>

      <Result />
    </RequestContainer>
  );
};

export default Request;
