import { useFormContext } from "react-hook-form";
import SelectBox from "../../form/selectBox";
import {
  ContentArea,
  ContentContainer,
  ContentError,
  ContentTitle,
} from "../Style";

const SelectList = ({
  config: { title, name, required, errorMessage },
  list,
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const select = list.find(({ id }) => id === watch(name)) ?? list[0];

  const error = errors[name] ? errorMessage[errors[name].type] : "";

  return (
    <ContentContainer>
      <ContentTitle isRequired={required}>{title}</ContentTitle>
      <ContentArea>
        <SelectBox
          width={300}
          select={{
            value: select.id,
            title: select.nameEng,
          }}
        >
          {list.map(({ id, nameEng }) => (
            <SelectBox.Option
              value={id}
              key={id}
              option={register(name, {
                required,
              })}
            >
              {nameEng}
            </SelectBox.Option>
          ))}
        </SelectBox>
        {error && <ContentError>{error}</ContentError>}
      </ContentArea>
    </ContentContainer>
  );
};

export default SelectList;
