import Excel from "@/components/common/excel";
import Table from "@/components/common/table";
import usePagination from "@/hooks/utils/usePagination";
import { numberWithCommas, sliceDate } from "@/utils/function";
import { common } from "@text";

const { blank } = common;

const CreateItem = ({ settlementData }) => {
  const { pageRender } = usePagination({
    totalPage: settlementData.totalPage,
    pagePerBlock: 10,
  });

  return (
    <>
      <Table.Body>
        {settlementData.settlementList.length === 0 && (
          <Table.Row>
            <Table.Colspan colspan={6}>{blank}</Table.Colspan>
          </Table.Row>
        )}
        {settlementData.settlementList.map(
          ({
            artistNameEng,
            period: { start, end },
            amount,
            admin: { name, id },
            reportFile,
          }) => (
            <Table.Row key={`${start}_${end}_${artistNameEng}`}>
              <Table.Item>{artistNameEng}</Table.Item>
              <Table.Item>
                {sliceDate(start)} ~ {sliceDate(end)}
              </Table.Item>
              <Table.Item>{numberWithCommas(amount)}</Table.Item>
              <Table.Item>
                <p>{name}</p>
                <p>({id})</p>
              </Table.Item>
              <Table.Item>
                <Excel link={reportFile} />
              </Table.Item>
            </Table.Row>
          )
        )}
      </Table.Body>
      <Table.Pagination>{pageRender()}</Table.Pagination>
    </>
  );
};

export default CreateItem;
