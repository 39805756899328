import { InformationButton } from "./Style";
import { FormProvider, useForm } from "react-hook-form";
import { artist } from "@text";
import Initial from "@/components/common/initial";
import SettlementInput from "@/components/common/tableForm/settlementInput";
import { useUpdateUserSettlementInfoMutation } from "@/hooks/api/settlement";

const {
  information: {
    initial,
    button: { edit, content },
    required,
    alert: { first, update },
  },
} = artist;

const defaultValues = {
  bankName: "",
  bankAccount: "",
  bankAccountHolder: "",
  businessRegistration: "",
  isEdit: false,
};

const useInformation = (information) => {
  const businessRegistration = information.data?.businessRegistration;
  let businessRegistrationFileName = "";

  if (businessRegistration) {
    const businessRegistrationSplit = businessRegistration.split("/");
    businessRegistrationFileName = businessRegistrationSplit.pop().split("_");
    businessRegistrationFileName.shift();
    businessRegistrationFileName = businessRegistrationFileName.join("_");
  }

  const form = useForm({
    defaultValues: {
      ...defaultValues,
      ...information.data,
      businessRegistrationFileName,
      isInitial: information.message === "CREATE NEW SETTLEMENT INFO",
    },
  });
  const { handleSubmit, watch, setValue } = form;

  const successCallback = () => {
    changeEdit();
    window.alert(information.isInformationInitial ? first : update);
  };

  const informationMutation =
    useUpdateUserSettlementInfoMutation(successCallback);

  const { isInitial, isEdit } = watch();

  const changeEdit = () => {
    setValue("isEdit", !isEdit);
  };

  const onSubmit = (data) => {
    if (isEdit) {
      informationMutation(data);
    } else {
      changeEdit();
    }
  };

  return { form, submit: handleSubmit(onSubmit), isEdit, isInitial };
};

const Information = ({ information }) => {
  const { form, submit, isEdit, isInitial } = useInformation(information);

  return (
    <FormProvider {...form}>
      {isInitial ? (
        <Initial form={initial} />
      ) : (
        <>
          <SettlementInput required={required} isInfo />
          <InformationButton onClick={submit}>
            {isEdit ? edit : content}
          </InformationButton>
        </>
      )}
    </FormProvider>
  );
};

export default Information;
