import Table from "@/components/common/table";
import {
  RequestStatusFinish,
  RequestStatusReady,
  RequestTableItem,
} from "./Style";
import { numberWithCommas, sliceDate } from "@/utils/function";
import { UNSETTLEMENT } from "@/utils/constants";
import usePagination from "@/hooks/utils/usePagination";
import { common } from "@text";
import usePopup from "@/hooks/utils/usePopup";

const { blank } = common;

export const ResultStatus = ({ status, requestCode }) => {
  const { handleOpenPopup } = usePopup({
    submit: () => {
      // navigate("/admin/settlement/list");
    },
    width: 1072,
    height: 929,
  });

  return status === UNSETTLEMENT ? (
    <RequestStatusReady>{status}</RequestStatusReady>
  ) : (
    <RequestStatusFinish
      onClick={() => handleOpenPopup(`/resultPopup?reportCode=${requestCode}`)}
    >
      {status}
    </RequestStatusFinish>
  );
};

const RequestItem = ({ requestData }) => {
  const { pageRender } = usePagination({
    totalPage: requestData.totalPage,
    pagePerBlock: 10,
  });

  return (
    <>
      <Table.Body>
        {requestData.settlementRequestList.length === 0 && (
          <Table.Row>
            <Table.Colspan colspan={6}>{blank}</Table.Colspan>
          </Table.Row>
        )}
        {requestData.settlementRequestList.map(
          ({
            requestCode,
            artistNameEng,
            requestDate,
            settlementPeriod,
            settlementAmount,
            settlementStatus,
          }) => (
            <Table.Row key={`${requestCode}-${requestDate}`}>
              <RequestTableItem>{requestCode}</RequestTableItem>
              <RequestTableItem>{artistNameEng}</RequestTableItem>
              <RequestTableItem>{sliceDate(requestDate)}</RequestTableItem>
              <RequestTableItem>
                {sliceDate(settlementPeriod.start)} ~{" "}
                {sliceDate(settlementPeriod.end)}
              </RequestTableItem>
              <RequestTableItem>
                {numberWithCommas(settlementAmount)}
              </RequestTableItem>
              <RequestTableItem>
                <ResultStatus
                  status={settlementStatus}
                  requestCode={requestCode}
                />
              </RequestTableItem>
            </Table.Row>
          )
        )}
      </Table.Body>
      <Table.Pagination>{pageRender()}</Table.Pagination>
    </>
  );
};

export default RequestItem;
