import Search from "@/components/common/search";
import { ArtistListContainer } from "./Style";
import Result from "@/components/admin/artist/result";
import useUrl from "@/hooks/utils/useUrl";
import { admin } from "@/text";

const {
  artist: {
    list: {
      search: { title, list },
    },
  },
} = admin;

const ArtistList = () => {
  const { changeQuery } = useUrl();

  return (
    <ArtistListContainer>
      <Search submit={changeQuery}>
        <Search.Input title={title} typeList={list} width={"130"} />
      </Search>

      <Result />
    </ArtistListContainer>
  );
};

export default ArtistList;
