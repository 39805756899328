const salesData = {
  "2023-03-30": {
    updateTime: "2021-08-01 00:00:00",
    totalPage: 12,
    sellInfo: {
      paymentCurrency: "KRW",
      sellTotal: "580000",
      settlementTotal: "460000",
    },
    sellReport: [
      {
        name: "상품명",
        option: "옵션명",
        paymentCurrency: "KRW", // 영문: USD, 일문: JPY
        price: {
          sell: 1000,
          settlement: 2300,
          total: {
            sell: 30000,
            settlement: 26000,
          },
        },
        sellCount: 20,
      },
      {
        name: "상품명",
        option: "옵션명",
        paymentCurrency: "KRW", // 영문: USD, 일문: JPY
        price: {
          sell: 1000,
          settlement: 2300,
          total: {
            sell: 30000,
            settlement: 26000,
          },
        },
        sellCount: 20,
      },
      {
        name: "상품명",
        option: "옵션명",
        paymentCurrency: "KRW", // 영문: USD, 일문: JPY
        price: {
          sell: 1000,
          settlement: 2300,
          total: {
            sell: 30000,
            settlement: 26000,
          },
        },
        sellCount: 20,
      },
    ],
  },
  "2023-03-31": [],
};

export default salesData;
