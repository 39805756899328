import Search from "@/components/common/search";
import { CreateContainer } from "./Style";
import Result from "@/components/admin/settlement/create";
import { admin } from "@text";
import { useArtistIdList } from "@/hooks/api/artist";
import { useCreateSettlementReportMutation } from "@/hooks/api/settlement";
import dayjs from "dayjs";

const {
  settlement: {
    create: {
      search: {
        button,
        select: { title: selectTitle },
        calendar: { title: calendarTitle },
      },
    },
  },
} = admin;

const Create = () => {
  const { artistIdList, isSuccess: isArtistSuccess } = useArtistIdList();
  const createSettlementReportMutation = useCreateSettlementReportMutation();

  const submit = ({ id, endDate, startDate }) => {
    if (id) {
      const body = {
        id,
        endDate,
        startDate,
      };

      createSettlementReportMutation(body);
    }
  };

  const checkEndDate = (date) => {
    const current = dayjs().subtract(7, "day");

    if (current.isBefore(date)) {
      alert("오늘 포함 이후 날짜는 선택할 수 없습니다.");
      return true;
    }

    return false;
  };

  return (
    <CreateContainer>
      <Search button={button} submit={submit}>
        {isArtistSuccess && (
          <Search.Select
            title={selectTitle}
            artistList={artistIdList.success.data}
            name={"id"}
            blank={"선택"}
          />
        )}
        <Search.Calendar title={calendarTitle} checkEndDate={checkEndDate} />
      </Search>

      <Result />
    </CreateContainer>
  );
};

export default Create;
