import { useFormContext } from "react-hook-form";
import EditInfo from "../../inputTable/editInfo";
import FileForm from "../../inputTable/fileForm";
import InputList from "../../inputTable/inputList";
import { ContentContainer } from "../Style";
import { common } from "@text";

const {
  tableForm: {
    settlement: { bank, businessRegister },
  },
} = common;

const SettlementInput = ({ required, isInfo }) => {
  const { watch } = useFormContext();
  const isEdit = watch("isEdit");

  return (
    <ContentContainer>
      <tbody>
        {isEdit && <EditInfo />}
        <InputList config={{ ...bank, required: required.bank }} />
        <FileForm
          config={{
            ...businessRegister,
            required: required.businessRegister,
            isInfo,
          }}
        />
      </tbody>
    </ContentContainer>
  );
};

export default SettlementInput;
