import Result from "@/components/admin/settlement/list";
import { ListContainer } from "./Style";
import Search from "@/components/common/search";
import useUrl from "@/hooks/utils/useUrl";
import { admin } from "@text";
import { useArtistIdList } from "@/hooks/api/artist";

const {
  settlement: {
    list: {
      search: {
        select: { title: selectTitle },
        radio: { title: radioTitle, radioList },
        calendar: { title: calendarTitle },
      },
    },
  },
} = admin;

const List = () => {
  const { changeQuery } = useUrl();
  const { artistIdList, isSuccess: isArtistSuccess } = useArtistIdList();

  return (
    <ListContainer>
      <Search submit={changeQuery}>
        {isArtistSuccess && (
          <Search.Select
            title={selectTitle}
            artistList={artistIdList.success.data}
            name={"id"}
          />
        )}

        <Search.Radio title={radioTitle} radioList={radioList} name={"type"} />
        <Search.Calendar title={calendarTitle} />
      </Search>

      <Result />
    </ListContainer>
  );
};

export default List;
