export const settlementList = [
  {
    id: "1",
    code: "P00001",
    artist: "zkzk",
    ListDate: "2021-08-01",
    period: "2021-08-01 ~ 2021-08-31",
    price: "100000",
    status: "미정산",
  },
  {
    id: "2",
    code: "P00002",
    artist: "zkzk",
    ListDate: "2021-08-01",
    period: "2021-08-01 ~ 2021-08-31",
    price: "100000",
    status: "정산 완료",
  },
  {
    id: "3",
    code: "P00003",
    artist: "zkzk",
    ListDate: "2021-08-01",
    period: "2021-08-01 ~ 2021-08-31",
    price: "100000",
    status: "정산 완료",
  },
  {
    id: "4",
    code: "P00004",
    artist: "zkzk",
    ListDate: "2021-08-01",
    period: "2021-08-01 ~ 2021-08-31",
    price: "100000",
    status: "미정산",
  },
  {
    id: "5",
    code: "P00005",
    artist: "zkzk",
    ListDate: "2021-08-01",
    period: "2021-08-01 ~ 2021-08-31",
    price: "100000",
    status: "미정산",
  },
];
