import { Button } from "@/styles/common";
import styled from "styled-components";

export const RegistrationButton = styled(Button)`
  width: ${(props) => props.theme.pxToRem(100)};

  padding: ${(props) => props.theme.pxToRem(5)} 0;
  margin-left: ${(props) => props.theme.pxToRem(8)};

  font-weight: 500;

  background-color: ${(props) => props.theme.colors.white};
  border-color: ${(props) => props.theme.colors.line};
  color: ${(props) => props.theme.colors.black};

  :disabled {
    color: ${(props) => props.theme.colors.line};
  }
`;
