import { SERVER_URL } from "@/api/info";
import userList from "@/mocks/data/common/user";
import { rest } from "msw";

const auth = [
  rest.post(`${SERVER_URL}/auth/login`, (req, res, ctx) => {
    const { id, pw, type } = req.body;
    const user = userList.find(
      (user) => user.id === id && user.pw === pw && user.type === type
    );

    if (!user) {
      return res(
        ctx.status(401),
        ctx.json({
          message: "false",
        })
      );
    } else {
      const message = user.isTemp
        ? "USE TEMP PASSWORD"
        : user.isNew
        ? "FIRST LOGIN"
        : "SUCCESS";

      return res(
        ctx.status(200),
        ctx.json({
          success: {
            message,
            data: user.type,
          },
        }),
        ctx.cookie("token", user.token)
      );
    }
  }),
  rest.get(`${SERVER_URL}/auth/auth/check`, (req, res, ctx) => {
    const token = req.cookies.token;
    const user = userList.find((user) => user.token === token);

    if (!token) {
      return res(
        ctx.status(401),
        ctx.json({
          message: "로그인이 필요합니다.",
        })
      );
    } else {
      return res(
        ctx.status(200),
        ctx.json({
          data: user,
        })
      );
    }
  }),
  rest.post(`${SERVER_URL}/auth/password`, (req, res, ctx) => {
    const { id, email } = req.body;
    const user = userList.find(
      (user) => user.id === id && user.email === email
    );

    if (!user) {
      return res(ctx.status(401));
    } else {
      return res(ctx.status(200));
    }
  }),

  rest.put(`${SERVER_URL}/auth/password`, (req, res, ctx) => {
    const { password } = req.body;
    const token = req.cookies.token;

    const user = userList.find(
      (user) => user.token === token && user.pw === password
    );

    if (!user) {
      return res(ctx.status(401));
    } else {
      return res(ctx.status(200));
    }
  }),

  rest.put(`${SERVER_URL}/auth/logout`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set("Set-Cookie", `token=; Max-Age=0; path=/; HttpOnly`)
    );
  }),
];

export default auth;
