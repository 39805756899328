const artistData = [
  {
    id: "chanran1",
    enName: "ChanRan1",
    krName: "차란",
    profileUpdate: "2021-08-01",
    settlementUpdate: "2021-08-01",
  },
  {
    id: "chanran2",
    enName: "ChanRan2",
    krName: "차란",
    profileUpdate: "2021-08-01",
    settlementUpdate: "2021-08-01",
  },
  {
    id: "chanran3",
    enName: "ChanRan3",
    krName: "차란",
    profileUpdate: "2021-08-01",
    settlementUpdate: "2021-08-01",
  },
  {
    id: "chanran4",
    enName: "ChanRan4",
    krName: "차란",
    profileUpdate: "2021-08-01",
    settlementUpdate: "2021-08-01",
  },
];

/*
*
  {
    token: "QWEQWEeeee",
    id: "qweqwe",
    password: "qwe",
    name_han: "김민수",
    name_eng: "Minsoo Kim",
    profileImg: "http://localhost:3000/assets/profile/profile.png",
    storeBannerImg: "http://localhost:3000/assets/profile/profile.png",
    profile_han: "김민수",
    profile_eng: "Minsoo Kim",
    globalPrice: "1",
    phoneNum: "010-1234-5678",
    email: "qwe@qwe.kr",
    sns_instagram: "https://www.instagram.com/",
    sns_twitter: "https://www.facebook.com/",
    sns_blog: "https://www.naver.com/",
    sns_youtube: "https://www.youtube.com/",
    sns_grafolio: "https://www.grafolio.com/",
    sns_etc1: "",
    sns_etc2: "",
    firstLogin: false,
    level: 1,
    bank_name: "",
    bank_account: "",
    bank_accountHolder: "",
    businessRegistration: "",
    profileUpdate: "2021-08-01",
    settlementUpdate: "2021-08-01",
    connectDate: "2021-08-01 10:10:10",
  },
*/

export default artistData;
