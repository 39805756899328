import { SubTitle } from "@/styles/common";
import { DashBoardLink, DashboardContainer, DashboardTd } from "./Style";
import { admin } from "@text";
import Table from "@/components/common/table";
import { useArtistUpdate } from "@/hooks/api/profile";
import Loading from "@/components/common/loading";
import { numberWithCommas, sliceDate } from "@/utils/function";
import { ResultStatus } from "@/components/admin/settlement/request/item";

const {
  dashboard: { artist, settlement, button },
} = admin;

const Dashboard = () => {
  const { artistData, isLoading, isSuccess } = useArtistUpdate();

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && (
        <DashboardContainer>
          <SubTitle>{artist.title}</SubTitle>
          <Table>
            <Table.Head>
              <Table.Row>
                {artist.head.map(({ title, width }) => (
                  <Table.Header width={width} key={title}>
                    {title}
                  </Table.Header>
                ))}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {artistData.success.data.artist.length === 0 && (
                <Table.Row>
                  <Table.Colspan colspan="5">
                    최근 업데이트된 아티스트가 없습니다.
                  </Table.Colspan>
                </Table.Row>
              )}
              {artistData.success.data.artist.map(
                ({
                  id,
                  nameEng,
                  nameKor,
                  updateDate: { profile, settlement },
                }) => (
                  <Table.Row key={id}>
                    <DashboardTd>{id}</DashboardTd>
                    <DashboardTd>{nameEng}</DashboardTd>
                    <DashboardTd>{nameKor}</DashboardTd>
                    <DashboardTd>{sliceDate(profile, "min")}</DashboardTd>
                    <DashboardTd>
                      {settlement ? sliceDate(settlement, "min") : "-"}
                    </DashboardTd>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>

          <DashBoardLink to={"/admin/artist?sort=4"}>{button}</DashBoardLink>

          <SubTitle isMargin>{settlement.title}</SubTitle>
          <Table>
            <Table.Head>
              <Table.Row>
                {settlement.head.map(({ title, width }) => (
                  <Table.Header width={width} key={title}>
                    {title}
                  </Table.Header>
                ))}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {artistData.success.data.settlement.length === 0 && (
                <Table.Row>
                  <Table.Colspan colspan="6">
                    정산 요청 내역이 없습니다.
                  </Table.Colspan>
                </Table.Row>
              )}
              {artistData.success.data.settlement.map(
                ({
                  code,
                  nameEng,
                  requestDate,
                  requestPeriod: { start, end },
                  amount,
                  state,
                }) => (
                  <Table.Row key={code}>
                    <DashboardTd>{code}</DashboardTd>
                    <DashboardTd>{nameEng}</DashboardTd>
                    <DashboardTd>{sliceDate(requestDate, "min")}</DashboardTd>
                    <DashboardTd>
                      {sliceDate(start)} ~ {sliceDate(end ?? "2023-04-01")}
                    </DashboardTd>
                    <DashboardTd>{numberWithCommas(amount)}</DashboardTd>
                    <DashboardTd>
                      <ResultStatus status={state} requestCode={code} />
                    </DashboardTd>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>

          <DashBoardLink to={"/admin/settlement/request"}>
            {button}
          </DashBoardLink>
        </DashboardContainer>
      )}
    </>
  );
};

export default Dashboard;
