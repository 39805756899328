import Info from "@/components/common/info";
import { IoCloseSharp } from "@react-icons/all-files/io5/IoCloseSharp";
import { useFormContext } from "react-hook-form";
import {
  ContentArea,
  ContentContainer,
  ContentInfo,
  ContentMobileTitle,
  ContentTitle,
} from "../Style";
import {
  FileCancelButton,
  FileError,
  FileFormBox,
  FileFormBoxSize,
  FileFormBoxTitle,
} from "./Style";
import {
  downloadFile,
  convertByte,
  createGrave,
  imageExtension,
  convertFile,
} from "@/utils/function";
import File from "../../form/file";

const FileForm = ({
  config: {
    title,
    name,
    info,
    errorMessage,
    required,
    isInfo,
    button,
    accept = "image/png, image/jpeg",
    checkExtension = imageExtension,
  },
}) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { isEdit } = watch();
  const value = watch(name);
  const fileName = watch(`${name}FileName`);
  const fileSize = watch(`${name}FileSize`);

  const deleteValue = (e) => {
    e.preventDefault();
    setValue(name, null);
  };

  const error = errors[name] ? errorMessage[errors[name].type] : "";

  return (
    <ContentContainer>
      <ContentTitle isRequired={isEdit && required}>{title}</ContentTitle>

      <ContentArea>
        <File>
          {isEdit && (
            <File.Input
              option={{
                ...register(name, {
                  required,
                }),
                accept,
              }}
              fileName={register(`${name}FileName`)}
              fileSize={register(`${name}FileSize`)}
              checkExtension={checkExtension}
            />
          )}
          <ContentMobileTitle isRequired={isEdit && required}>
            {title}
          </ContentMobileTitle>
          {value && (
            <FileFormBox
              onClick={() =>
                !isEdit && downloadFile(convertFile(value), fileName)
              }
            >
              <FileFormBoxTitle>{fileName}</FileFormBoxTitle>
              <FileFormBoxSize>
                ({convertByte(fileSize)}
                mb)
              </FileFormBoxSize>
              {isEdit && (
                <FileCancelButton onClick={deleteValue}>
                  <IoCloseSharp size={24} />
                </FileCancelButton>
              )}
            </FileFormBox>
          )}
          {isEdit && <File.Button>{button}</File.Button>}
          {isEdit && isInfo && (
            <ContentInfo>
              <Info>{createGrave(info)}</Info>
            </ContentInfo>
          )}
          {error && <FileError>{error}</FileError>}
        </File>
      </ContentArea>
    </ContentContainer>
  );
};

export default FileForm;
