import { Form, FormSubmitButton, SubTitle } from "@/styles/common";
import {
  RegistrationButtonBox,
  RegistrationCancelButton,
  RegistrationContainer,
  RegistrationSubmitButton,
} from "./Style";
import { FormProvider, useForm } from "react-hook-form";
import RegistrationInput from "@/components/common/tableForm/registrationInput";
import { admin } from "@/text";
import Modal from "@/components/common/modal";
import useToggle from "@/hooks/utils/useToggle";
import useUrl from "@/hooks/utils/useUrl";
import { useArtistIdList } from "@/hooks/api/artist";
import { useCreateSettlementMutation } from "@/hooks/api/settlement";
import { useState } from "react";

const {
  settlement: {
    list: {
      registration: { title, required, button, modal },
    },
  },
} = admin;

const defaultValues = {
  artist: null,
  settlementType: "",
  requestCode: "",
  settlementFile: null,

  isEdit: true,
};

const Registration = () => {
  const form = useForm({
    defaultValues,
  });
  const [isShow, toggleModal] = useToggle(false);
  const { changeLink } = useUrl();
  const [state, setState] = useState("alert");
  const { artistIdList, isSuccess: isArtistSuccess } = useArtistIdList();
  const createSettlement = useCreateSettlementMutation(
    () => changeLink("/admin/settlement/list"),
    () => setState("fail")
  );

  const { handleSubmit } = form;

  const { modalTitle, modalDesc, modalButton } = modal[state];

  return (
    <RegistrationContainer>
      <SubTitle>{title}</SubTitle>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(toggleModal)}>
          {isArtistSuccess && (
            <RegistrationInput
              required={required}
              artistList={artistIdList.success.data}
              name={"id"}
            />
          )}
          <FormSubmitButton type="submit">{button}</FormSubmitButton>
        </Form>
      </FormProvider>

      <Modal isShow={isShow}>
        <Modal.Title>{modalTitle}</Modal.Title>

        {modalDesc.map((desc) => (
          <Modal.Desc key={desc}>{desc}</Modal.Desc>
        ))}

        {state === "alert" && (
          <RegistrationButtonBox>
            <RegistrationCancelButton onClick={toggleModal}>
              {modalButton.cancel}
            </RegistrationCancelButton>
            <RegistrationSubmitButton
              onClick={() => {
                const data = form.getValues();
                createSettlement(data);
              }}
            >
              {modalButton.confirm}
            </RegistrationSubmitButton>
          </RegistrationButtonBox>
        )}
        {state === "fail" && (
          <RegistrationButtonBox>
            <RegistrationSubmitButton
              width={"100%"}
              onClick={() => {
                toggleModal();
                setState("alert");
              }}
            >
              {modalButton.confirm}
            </RegistrationSubmitButton>
          </RegistrationButtonBox>
        )}
      </Modal>
    </RegistrationContainer>
  );
};

export default Registration;
