import { BiCalendar } from "@react-icons/all-files/bi/BiCalendar";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { CalendarContainer } from "./Style";
import { sliceDate } from "@/utils/function";

const CalendarForm = ({ option, checkDate, value, isMonth, isLast }) => {
  const convertDate = (date) => {
    if (isLast) {
      return dayjs(date).endOf("month");
    }
    return dayjs(date);
  };

  const changeCalendar = (date, stringDate) => {
    const current = dayjs("2024-01-01");
    const select = convertDate(date);

    if (select.isBefore(current)) {
      return;
    }

    if (checkDate && checkDate(date)) return;

    option.onChange({
      target: {
        name: option.name,
        value: sliceDate(select.format("YYYY-MM-DD")),
      },
    });
  };

  return (
    <CalendarContainer>
      <DatePicker
        picker={isMonth ? "month" : "date"}
        bordered={false}
        allowClear={false}
        suffixIcon={<BiCalendar size={25} />}
        value={dayjs(value)}
        onChange={changeCalendar}
      />
    </CalendarContainer>
  );
};

export default CalendarForm;
