import { SERVER_URL } from "@/api/info";
import { rest } from "msw";

const product = [
  rest.get(`${SERVER_URL}/product/settlement`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: {
          data: {
            totalItem: 1333, // 페이지네이션 총 아이템 수
            totalPage: 12, // 페이지네이션 총 페이지 수

            productSettlementList: [
              {
                img: "test",
                artistNameEng: "아티스트 영문명",
                productName: "상품명",
                productCode: "P111133333",
                price: {
                  nation: 3000, // 국내 판매가
                  nationSettlement: 3000, // 국내몰 정산가
                  abroadSettlement: 3000, // 영문몰 정산가
                  japanSettlement: 3000, // 일문몰 정산가
                },
              },
            ],
          },
        },
      })
    );
  }),
  rest.put(`${SERVER_URL}/product/settlement`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
];

export default product;
