import { useFormContext } from "react-hook-form";
import FileForm from "../../inputTable/fileForm";
import RadioList from "../../inputTable/radioList";
import SelectList from "../../inputTable/selectList";
import SingleInput from "../../inputTable/singleInput";
import { ContentContainer } from "../Style";
import { RegistrationButton } from "./Style";
import { common } from "@text";
import usePopup from "@/hooks/utils/usePopup";
import { SETTLEMENT_TYPE } from "@/utils/constants";
import { excelExtension } from "@/utils/function";

const {
  tableForm: {
    registration: { artist, settlementType, requestCode, settlementFile },
  },
} = common;

const RegistrationInput = ({ required, artistList }) => {
  const { watch, setValue } = useFormContext();

  const {
    artist: artistId,
    settlementType: settlementTypeValue,
    requestCode: requestCodeValue,
  } = watch();

  const { handleOpenPopup } = usePopup({
    submit: (data) => {
      setValue("requestCode", data.requestCode);
    },
    width: 1072,
    height: 929,
  });

  const getRequestCodeOption = () => {
    const disabled =
      settlementTypeValue !== SETTLEMENT_TYPE.request || requestCodeValue;

    return {
      disabled,
      value: requestCodeValue,
      onChange: (e) => {},
      onClick: handleOpenPopup,
    };
  };

  const requestOption = getRequestCodeOption();
  const disabledRequestCode = settlementTypeValue !== SETTLEMENT_TYPE.request;

  return (
    <ContentContainer>
      <tbody>
        <SelectList
          config={{
            ...artist,
            required: required.artist,
          }}
          list={artistList}
        />
        <RadioList
          config={{ ...settlementType, required: required.settlementType }}
        />
        <SingleInput
          config={{
            ...requestCode,
            required: !disabledRequestCode,
            option: requestOption,
          }}
        >
          <RegistrationButton
            disabled={disabledRequestCode}
            type="button"
            onClick={() => handleOpenPopup(`/searchPopup?id=${artistId}`)}
          >
            {requestCode.button}
          </RegistrationButton>
        </SingleInput>

        {!disabledRequestCode && (
          <FileForm
            config={{
              ...settlementFile,
              required: !disabledRequestCode && required.settlementFile,
              accept:
                ".xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
              checkExtension: excelExtension,
            }}
          />
        )}
      </tbody>
    </ContentContainer>
  );
};

export default RegistrationInput;
