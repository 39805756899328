const settlementData = {
  "": [
    {
      _id: 1,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 2,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 3,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 4,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 5,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 6,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 7,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
  ],
  "2021-01-01": [
    {
      _id: 1,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 2,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 3,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 4,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 5,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 6,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 7,
      settlementPeriod: "2021-01-01~2021-01-31",
      settlementDate: "2021-01-01",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
  ],
  "2023-03-30": [
    {
      _id: 100,
      settlementPeriod: "2023-03-30~2021-05-20",
      settlementDate: "2023-03-30",
      endDate: "2021-05-20",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 101,
      settlementPeriod: "2023-03-30~2021-05-20",
      settlementDate: "2023-03-30",
      endDate: "2021-05-20",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 102,
      settlementPeriod: "2023-03-30~2021-05-20",
      settlementDate: "2023-03-30",
      endDate: "2021-05-20",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 103,
      settlementPeriod: "2023-03-30~2021-05-20",
      settlementDate: "2023-03-30",
      endDate: "2021-05-20",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 104,
      settlementPeriod: "2023-03-30~2021-05-20",
      settlementDate: "2023-03-30",
      endDate: "2021-05-20",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
    {
      _id: 105,
      settlementPeriod: "2023-03-30~2021-05-20",
      settlementDate: "2023-03-30",
      endDate: "2021-05-20",
      settlementPrice: 100000,
      settlementFile:
        "https://ssl.pstatic.net/melona/libs/1432/1432563/bdcf218f83cff2ae3bea_20230329170400866.jpg",
    },
  ],
};

export const unSettlementData = {
  settlementPeriod: "2021-01-01 11:11:11",
  settlementKrPrice: "30000",
  settlementEnPrice: "2000",
  settlementJpPrice: "3000000",
  settlementTotalPrice: "3032000",
  lastUpdate: "2021-01-11 11:11:11",
  isSuccess: true,
};

export default settlementData;
